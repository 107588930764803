html {
  min-height: 100%;
}
body {
  background: radial-gradient(rgba(0, 0, 91, 0.8), rgba(0, 0, 139, 0.8) 50%, rgba(29, 88, 176, 0.81) 65%, #d09d39 66%, #1d58b0 67%, #1d58b0 85%,  #00008d);
}
select {
  position: absolute;
  top: 15px;
}
h1.title {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 0;
  color: #c89025;
  text-shadow: black 0 0 5px;
  font-size: 66pt;
}

#prev {
  position: absolute;
  top: 50%;
  left: 5%;
  height: 5%;
  width: 5%;
  font-size: 16pt;
}
#next {
  position: absolute;
  top: 50%;
  left: 90%;
  height: 5%;
  width: 5%;
  font-size: 16pt;
}
#start {
  position: absolute;
  top: 50%;
  left: 38%;
  height: 5%;
  width: 20%;
  font-size: 16pt;
}

.game {
  font-family: Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif;
  color: #d3d3d3;
  text-shadow: black 1px 1px ;
  width: 70%;
  margin: 0 auto 0 auto;
  display: grid;
  grid-template: 125px 1fr 1fr 1fr 1fr 1fr / 1fr 1fr;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-items: center;
}
.game .question {
  grid-column: 1 / 3;
  grid-row: 1/1;
  margin: 0;
  font-size: 32pt;
}
.game .answer {
  position: relative;
  height: 70px;
  background: linear-gradient(rgba(24, 59, 118, 0.82) 50%, blue 100%);
  border: solid silver 3px;
  display: grid;
  align-items: center;
}
.game .answer p {
  font-weight: 700;
  font-size: 24pt;
  margin: 0;
  text-align: center;
}
.cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #c89025;
  display: grid;
  align-items: center;
}
.hidden {
  visibility: hidden;
}
